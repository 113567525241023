import { useState, useEffect, useRef } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Button from 'react-bootstrap/Button';
import { FiFacebook, FiInstagram, FiMapPin, FiPhone, FiCalendar } from 'react-icons/fi';
import SpecialOpenHours from './special-open-hours/special-open-hours.component';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './footer.styles.scss';

const Footer = () => {
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [showMapOffcanvas, setShowMapOffcanvas] = useState(false);
  const [showSpecialOpenHours, setShowSpecialOpenHours] = useState(false);


  const toggleOffcanvas = () => {
    setShowOffcanvas((prevState) => !prevState);
  };

  const toggleMapOffcanvas = () => {
    setShowMapOffcanvas((prevState) => !prevState);
  };

  const toggleSpecialOpenHours = () => {
    setShowSpecialOpenHours((prevState) => !prevState);
  };



  return (
    <>
      <footer className="footer-container">
        <div className="footer-icons">
          <a href="https://www.facebook.com/people/LunaMia/100063484542413/?locale=sv_SE" target="_blank" rel="noopener noreferrer">
            <FiFacebook className="icon facebook" />
          </a>

          <a href="https://www.instagram.com/lunamiapajala/" target="_blank" rel="noopener noreferrer">
            <FiInstagram className="icon instagram" />
          </a>

          <FiMapPin
            className="icon map-icon"
            onClick={toggleMapOffcanvas}
          />

          <a href="tel:+46765251541">
            <FiPhone className="icon phone-icon" />
          </a>
        </div>

        <Button variant="outline-light" className='footer-btn border-style-button' onClick={toggleOffcanvas}>
          Öppettider
        </Button>
      </footer>

      <Offcanvas
        show={showOffcanvas}
        onHide={toggleOffcanvas}
        placement="bottom"
        backdropClassName="offcanvas-backdrop blur"
        style={{ width: '100%', opacity: 0.95 }}
        className="offcanvas-open-hours border-style-2"
      >
        <Offcanvas.Header closeButton>
          <div className='menu-header-wrapper'>
            <Offcanvas.Title className='menu-header'>Öppettider</Offcanvas.Title>
            {/* <Button className="special-hours-btn border-style-button" onClick={toggleSpecialOpenHours}>
              <FiCalendar className="icon calendar" />
              Avvikande dagar
            </Button> */}
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <p>Måndag - Fredag: <span className="open-hours">10:00 - 17:00</span></p>
          <p>Lördag: <span className="open-hours">10:00 - 14:00</span></p>
          <p>Söndag: <span className="open-hours red">Stängt</span></p>
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas
        show={showMapOffcanvas}
        onHide={toggleMapOffcanvas}
        placement="bottom"
        style={{ width: '100%', height: '60vh' }}
        className="offcanvas-map border-style-2"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className='menu-header'>Karta</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <p>Tornedalsvägen 2, 984 31 Pajala</p>
          <div className="store-map">
            <iframe
              title="Embedded map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6181.552968434586!2d23.357582709301113!3d67.21134856232129!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x45d3f5fee66a1d81%3A0xe90f77fdf77ffe7!2sLunamia!5e0!3m2!1ssv!2sse!4v1679939109940!5m2!1ssv!2sse"
              allowFullScreen="true"
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              className="google-map"
            ></iframe>
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      {/* <SpecialOpenHours show={showSpecialOpenHours} onClose={toggleSpecialOpenHours} /> */}
    </>
  );
};

export default Footer;
