import { useLayoutEffect, Suspense, lazy, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Routes, Route } from 'react-router-dom';

import NavigationBar from './02_COMPONENTS/01_navbar/navbar.component';
import Footer from './02_COMPONENTS/02_footer/footer.component';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './App.scss';

// Lazy load the components
const Home = lazy(() => import('./03_NAVIGATION/01_home/home.component'));
const About = lazy(() => import('./03_NAVIGATION/02_about/about.component'));
const Brands = lazy(() => import('./03_NAVIGATION/03_brands/brands.component'));
const Contact = lazy(() => import('./03_NAVIGATION/04_contact/contact.component'));

const ScrollToTop = () => {
  const location = useLocation();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return null;
};

const App = () => {
  const location = useLocation();
  const [_key, setKey] = useState(location.key);

  useEffect(() => {
    setKey(location.key);
  }, [location]);

  return (
    <div className='LunaMia-app'>
      <ScrollToTop />
      <NavigationBar />
          <Suspense fallback={<div>Laddar...</div>}>
            <Routes>
              <Route path='/' element={<Home />} />
              <Route path='/about' element={<About />} />
              <Route path='/brands' element={<Brands />} />
              <Route path='/contact' element={<Contact />} />
            </Routes>
          </Suspense>
      <Footer />
    </div>
  );
};

export default App;