import { useEffect, useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { FiX, FiHome, FiShoppingBag, FiStar, FiPhone, FiMenu } from 'react-icons/fi';
import { Link, useLocation } from 'react-router-dom';

import Logo from '../../01_ASSETS/logos/LunaMia_logo_transparent_white_blackline.webp';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './navbar.styles.scss';

const NavigationBar = () => {
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [logoWidth, setLogoWidth] = useState(130);
  const [logoMarginLeft, setLogoMarginLeft] = useState('0px');
  const [activePage, setActivePage] = useState('home');

  const location = useLocation();

  const resetState = () => {
    setShowOffcanvas(false);
    setLogoWidth(130);
    setLogoMarginLeft('0px');
  };

  const toggleOffcanvas = () => {
    setShowOffcanvas((prevState) => !prevState);
    if (isMobile) {
      setLogoWidth((prevState) => (prevState === 130 ? 80 : 130));
      setLogoMarginLeft((prevState) => (prevState === '0px' ? '-100px' : '0px'));
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      if (scrollY > 50) {
        setLogoWidth(80);
      } else {
        setLogoWidth(130);
      }
    };

    const handleResize = () => {
      const windowIsMobile = window.innerWidth <= 768;
      setIsMobile(windowIsMobile);
      if (!windowIsMobile && !showOffcanvas) {
        setLogoWidth(130);
        setLogoMarginLeft('0px');
      }
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, [isMobile, showOffcanvas]);

  const handleSetActivePage = (page) => {
    setActivePage(page);
    resetState();
  };

  const menuItems = [
    { page: '', icon: <span className="nav-icon"><FiHome /></span>, label: 'Hem' },
    { page: 'about', icon: <span className="nav-icon"><FiStar /></span>, label: 'Butiken' },
    { page: 'brands', icon: <span className="nav-icon"><FiShoppingBag /></span>, label: 'Varumärken' },
    { page: 'contact', icon: <span className="nav-icon"><FiPhone /></span>, label: 'Kontakt' },
  ];

  return (
    <Navbar fixed="top" bg="black" variant="dark" expand="x-lg" className="Navbar">
      <Container>
        <div 
          className="navbar-logo-container"
          style={{ marginLeft: `${logoMarginLeft}` }}
        >
          <Navbar.Brand
            as={Link}
            to="/"
            className="navbar-logo-wrapper"
            onClick={() => handleSetActivePage('home')}
          >
            <img src={Logo} alt="Logo" className="navbar-logo" style={{ width: `${logoWidth}px` }} />
          </Navbar.Brand>
        </div>

        <Navbar.Toggle
          as={FiMenu}
          aria-controls="navbar-offcanvas"
          onClick={toggleOffcanvas}
          className={showOffcanvas ? 'menu-button-open' : ''}
        />
        <Offcanvas
          show={showOffcanvas}
          onHide={toggleOffcanvas}
          placement="end"
          variant="dark"
          style={{ width: '200px', opacity: 0.95 }}
          className="offcanvas-menu border-style-noborder"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title className="menu-header">Meny</Offcanvas.Title>
            <FiX 
              className="offcanvas-close" 
              onClick={toggleOffcanvas} 
              style={{ cursor: 'pointer' }} 
            />
          </Offcanvas.Header>

          <Offcanvas.Body>
            <Nav className="flex-column navlinks">
              {menuItems.map((item) => (
                <Nav.Link
                  key={item.page}
                  as={Link}
                  to={`/${item.page}`}
                  onClick={() => handleSetActivePage(item.page)}
                  className={`navlink ${location.pathname === `/${item.page}` ? 'active' : 'inactive'}`}
                >
                  {item.icon} {/* Replace FontAwesomeIcon with the icon component */}
                  {item.label}
                </Nav.Link>
              ))}
            </Nav>
          </Offcanvas.Body>
        </Offcanvas>
      </Container>
    </Navbar>
  );
};

export default NavigationBar;
